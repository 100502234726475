<template>
  <a-layout class="index animated fadeIn">
    <a-card class="mb10">
      <a-button
        size="small"
        @click="
          () => {
            $router.go(-1);
          }
        "
      >
        <LeftOutlined />返回
      </a-button>
      <span class="ml15 card_title">{{ $route.query.type == "add" ? "添加" : "修改" }}代理商级别</span>
    </a-card>
    <a-card>
      <a-form ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item ref="level_num" label="级别" name="level_num" help="级别范围1到5级" disabled>
          <a-input-number style="width: 100%" :max="5" :min="1" v-model:value="form.level_num" disabled />
        </a-form-item>
        <a-form-item ref="level_name" label="级别名称" name="level_name" help="最多10个字">
          <a-input v-model:value="form.level_name" />
        </a-form-item>
        <a-form-item ref="discount" label="开户折扣" name="discount">
          <a-input-number style="width: 100%" v-model:value="form.discount" suffix="折" />
          <span></span>
        </a-form-item>
        <a-form-item ref="renew_discount" label="续费折扣" name="renew_discount">
          <a-input-number style="width: 100%" v-model:value="form.renew_discount" suffix="折" />
        </a-form-item>

        <a-form-item label="是否OEM" name="">
          <a-radio-group v-model:value="form.is_oem">
            <a-radio :value="1"> 允许 </a-radio>
            <a-radio :value="0"> 不允许 </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="自定义产品价格" name="">
          <a-radio-group v-model:value="form.is_prouduct_price">
            <a-radio :value="1"> 允许 </a-radio>
            <a-radio :value="0"> 不允许 </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 8, offset: 2 }">
          <a-button type="primary" @click="onSubmit"> 保存 </a-button>
        </a-form-item>
      </a-form>
    </a-card>
  </a-layout>
</template>

<script>
import { LeftOutlined } from "@ant-design/icons-vue";
import { ref, onMounted, reactive, toRefs, inject } from "vue";
import { setAgentLevelAdd, setAgentLevelEdit, getAgentLevelMore } from "@/api/agent";
import { message } from "ant-design-vue";
import { $iscode } from "@/utils/app";
// 初始化默认筛选项数值
let defForm = {
  level_num: 1,
  level_name: "",
  discount: 0,
  renew_discount: 0,
  is_oem: 1,
  is_prouduct_price: 1,
};
export default {
  components: {
    LeftOutlined,
  },

  setup(props, ctx) {
    const form = ref({ ...defForm });
    const state = reactive({
      labelCol: { span: 2 },
      wrapperCol: { span: 8 },
      rules: {
        level_num: [{ required: true, message: "请输入代理商级别" }],
        level_name: [
          { required: true, message: "请输入等级名称" },
          { max: 10, message: "最多10个字" },
        ],
        discount: [{ required: true, message: "请输入开户折扣" }],
        renew_discount: [{ required: true, message: "请输入续费折扣" }],
      },
    });
    const ruleForm = ref();
    const $route = inject("$route");
    const $Modal = inject("$Modal");
    const $router = inject("$router");
    if($route.query.max){
      form.value.level_num=Number($route.query.max)+1
    }
    //获取详情
    const getMore = async (id) => {
      const hide = message.loading("正在加载中...", 0);
      let res = await getAgentLevelMore(id).then((res) => res.data);
      if ($iscode(res)) {
        form.value = res.data;
        setTimeout(hide);
      } else {
        message.error(res.msg);
      }
    };
    // 提交
    const onSubmit = () => {
      ruleForm.value
        .validate()
        .then(async () => {
          let res;
          if ($route.query.type == "add") {
            res = await setAgentLevelAdd({ ...form.value }).then((res) => res.data);
          } else {
            res = await setAgentLevelEdit($route.query.id, { ...form.value }).then((res) => res.data);
          }
          if ($iscode(res, true)) {
            setTimeout(() => {
              $router.go(-1);
            }, 1000);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    onMounted(() => {
      if ($route.query.type == "edit") {
        getMore($route.query.id);
      }
    });
    return {
      form,
      state,
      ...toRefs(state),
      ruleForm,
      onSubmit,
    };
  },
};
</script>
